import React, { useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/academyPayments/Layout"
import "./academy.scss"
import styled from "styled-components"
import axios from "axios"
import qs from 'qs'

const InfoContainer = styled.div`
  padding: 1rem;
  max-width: 400px;
  /* border: 1px solid black; */
  margin: 0 auto;

  p {
    font-weight: bold;
  }
  span {
    font-weight: normal;
  }

  @media (max-width: 470px) {
    margin: 0 1rem;
  }
`
const RegBtn = styled.button`
  cursor: pointer;
  background-color: green;
  padding: 0.5rem;
  color: white;
  border: none;
  margin: 0.3rem;
  border-radius: 6px;
`
const DoneBtn = styled.button`
  cursor: pointer;
  background-color: red;
  padding: 0.5rem;
  color: white;
  border: none;
  margin: 0.3rem;
  border-radius: 6px;
`

const DoneModal = styled.div`
  position: absolute;
  border: 1px solid black;
  padding: 0.5rem;
  left: 50%;
  top: 30%;
  margin-left: -170px;
  width: 340px;
  background-color: white;
`

 function SuccessPage() {
  useEffect(() => {
    console.log("Using Effect")
    componentDidMount()
    return () => {
    }
  }, [])

  async function componentDidMount() {
    const fromLocalStorage = localStorage.getItem("submittedData")
    const dataFromStorage = JSON.parse(fromLocalStorage)
    const CORS_PROXY = "https://factorycat-proxy.herokuapp.com/?q="
    if(typeof window !== `undefined` && dataFromStorage) {
      // console.log(fromLocalStorage)
      // console.log(dataFromStorage)
      const response = await fetch(
            `${CORS_PROXY}https://tomcatequip.com/SchoolRegEP.php?path=schoolregister&` +
              new URLSearchParams({
                ycompany: dataFromStorage.company,
                yname: dataFromStorage.yourName,
                yphone: dataFromStorage.phone,
                yemail: dataFromStorage.email,
                aname: dataFromStorage.name,
                aphone: dataFromStorage.cellPhone,
                aemail: dataFromStorage.attendeeEmail,
                choiceone: dataFromStorage.firstDate,
              })
          ).then((response)=> {
            console.log("The respone is: " + response.status)
            if (response.status == 200) {
              addAttendee(dataFromStorage)
            }
          })
     } else {
       console.log("No data in local storage")
     }
  }

  async function addAttendee(dataFromStorage) {
    console.log("Adding attendee")
    console.log(JSON.stringify(dataFromStorage) + ": Local Storage Data")
    let postRequest = {
      action: `addAttendee`,
      aname: dataFromStorage.name,
      aemail: dataFromStorage.attendeeEmail,
      aphone: dataFromStorage.cellPhone,
      ycompany: dataFromStorage.company,
      yname: dataFromStorage.yourName,
      yphone: dataFromStorage.phone,
      yemail: dataFromStorage.email,
      choiceone : dataFromStorage.classid,
    }
    await axios.post('https://www.factorycat.com/serviceSchoolFunctions.php', qs.stringify(postRequest), {
          headers: {
          'content-type': 'application/x-www-form-urlencoded',
          }
        }).then((response) => {
        }, 
        (error) => {
          console.log(error + ": Error")
        })
        localStorage.clear()
      }

  return (
    <Layout>
      <div className="success-page">
      <h1>
        You have Successfully Registered.
      </h1>
      <div>
        <p>You will receive an email confirmation shortly.</p>
        <p>
          For further questions please email us at{" "}
          <a 
            href="mailto:customerservice@rpscorporation.com">
            customerservice@rpscorporation.com
          </a>
        </p>
        <div>
          <Link to="/academy-payments">
            Register Another Attendee
          </Link> <br />
          <a href="http://www.portal.rpscorporation.com/">
            I Am Finished
          </a>
        </div>
      </div>
      </div>
      </Layout>
    )
  }
export default SuccessPage
